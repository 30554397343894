import { FilterKey } from "@/models/FilterKey";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { EventAggregationService } from "@/platform/EventAggregationService";
import Filter from "../../Filter.class";

export default class EducationalFormFilter extends Filter {

	public constructor(filterProvider: IFilterProvider, eventAggregationService?: EventAggregationService) {
		super(FilterKey.EDUCATIONAL_FORM, filterProvider, eventAggregationService);
	}
}
