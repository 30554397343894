import { computed, Ref } from 'vue';
import { Action, Product, Tracker, ITrackingData } from '@studyportals/datalake-event-tracker';
import { ControlEvent } from '@/models/filter-tracking/ControlEvent';

export default class Tracking {

	private readonly tracker: Tracker;
	private readonly product: Product;
	private readonly action: Ref<Action>;
	private readonly elementRef: Ref<Element | null>;
	private readonly dataRef: Ref<string>;
	private readonly label: string | undefined;
	private readonly eventToSubscribe: ControlEvent;

	public constructor(product: Product, elementRef: Ref<Element | null>,
		dataRef: Ref<string>, action: Ref<Action>,
		label: string | undefined, eventToSubscribe: ControlEvent
	) {
		this.product = product;
		this.tracker = new Tracker(product);
		this.elementRef = elementRef;
		this.dataRef = dataRef;
		this.action = action;
		this.label = label;
		this.eventToSubscribe = eventToSubscribe;
	}

	private trackingData = computed<ITrackingData>(() => {
		return {
			action: this.action.value,
			category: this.product,
			label: this.label,
			property: this.normalizeData(this.dataRef.value)
		};
	})

	public onMounted(): void {
		this.subscribe();
	}

	private subscribe(): void {
		const elementRef = this.elementRef;
		const element = elementRef?.value;

		if (!element) return;

		element.addEventListener(this.eventToSubscribe, () => this.track());
	}

	private track(): void {
		if (!this.tracker || !this.elementRef) {
			return;
		}

		try {
			this.tracker.trackStructuredEvent(this.trackingData.value);
		}
		catch (error) {
			console.warn(error);
		}
	}

	private normalizeData(data: string): string {
		return data.trim().replace("[-1,", "[null,").replace(",-1]", ",null]");
	}
}
