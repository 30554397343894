import { ClearFiltersRequestedEvent } from "@/events/ClearFiltersRequestedEvent";
import { IFilterPayload } from "@/interfaces/IFilterPayload";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { FilterKey } from "@/models/FilterKey";
import { EventAggregationService } from "@/platform/EventAggregationService";
import { ISubscriber } from "@studyportals/event-aggregation-service-interface";
import { EventAggregationServiceSubscription } from "@/platform/EventAggregationServiceSubscription";

export class CountryFilterSelection implements ISubscriber<ClearFiltersRequestedEvent> {
	private readonly eventAggregationServiceSubscription: EventAggregationServiceSubscription;

	private readonly filterProvider: IFilterProvider;
	private readonly locationFilterProvider: ILocationFilterProvider;

	public constructor(filterProvider: IFilterProvider, locationFilterProvider: ILocationFilterProvider,
		eventAggregationService?: EventAggregationService
	) {
		this.filterProvider = filterProvider;
		this.locationFilterProvider = locationFilterProvider;

		eventAggregationService ??= EventAggregationService.instance;
		this.eventAggregationServiceSubscription = eventAggregationService
			.subscribe(this).to(ClearFiltersRequestedEvent.EventType);
	}

	public dispose(): void {
		this.eventAggregationServiceSubscription.dispose();
	}

	public async notify(event: ClearFiltersRequestedEvent): Promise<void> {
		if (ClearFiltersRequestedEvent.is(event)) {
			const filters = event.filters.filter(filter => filter.key === FilterKey.COUNTRY);
			const areas = filters.map(filter => this.getAreasFor(filter.value)).flat();

			await this.clear(filters, areas);
		}
	}

	public async process(countries: string[]): Promise<void> {

		const areasPayload: IFilterPayload[] = [];
		countries.forEach(country => {
			areasPayload.push(...this.getAreasFor(country));
		})

		const countriesPayload = countries.map(country => {
			return { key: FilterKey.COUNTRY, value: country } as IFilterPayload;
		});

		await this.processAll(countriesPayload, areasPayload);
	}

	private async processAll(countries: IFilterPayload[], areas: IFilterPayload[]): Promise<void> {
		await this.filterProvider.processFiltersSelection([
			...countries,
			...areas
		]);
	}

	private async clear(countries: IFilterPayload[], areas: IFilterPayload[]): Promise<void> {
		if (countries.length === 0 && areas.length === 0) {
			return;
		}

		await this.filterProvider.clearSelection([
			...countries,
			...areas
		]);
	}

	private getAreasFor(country: string): IFilterPayload[] {
		const areasPayload: IFilterPayload[] = [];
		const areasForCountry = this.locationFilterProvider.identifyAreasFor(country);
		const areaFilterSelection = this.filterProvider.getFilterSelection(FilterKey.AREA)
			.filter(val => areasForCountry.includes(val));

		areasPayload.push(
			...areaFilterSelection.map(val => {
				return {
					key: FilterKey.AREA,
					value: val
				} as IFilterPayload
			})
		);

		return areasPayload;
	}
}
