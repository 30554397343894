import { computed, ref, watch } from "vue";
import { Autocomplete } from "../../infrastructure/Autocomplete";
import { IConfig } from "../../platform/IConfig";
import { AutocompleteSuggestion } from "../../infrastructure/AutocompleteSuggestion";
import { FilterKey, FilterKeysOfLocation } from "../../models/FilterKey";
import { IFilterProvider } from "../../interfaces/filter-providers/IFilterProvider";
import { IUniversityFilterProvider } from "../../interfaces/filter-providers/IUniversityFilterProvider";
import { IFilterPayload } from "@/interfaces/IFilterPayload";
import { EventAggregationService } from "@/platform/EventAggregationService";
import { ChangeHighVisibilityCountryOptionsEvent } from "../location/country/events/ChangeHighVisibilityCountryOptionsEvent";
import { ClearHighVisibilityCountryOptionsEvent } from "../location/country/events/ClearHighVisibilityCountryOptionsEvent";
import { ClearLocationFilterEvent } from "../location/events/ClearLocationFilterEvent";
import Filter from "@/Filter.class";
import { FilterChangeInitiatedEvent } from "../../events/FilterChangeInitiatedEvent";
import { FilterChangeCompletedEvent } from "../../events/FilterChangeCompletedEvent";

export class UniversityFilter extends Filter {

	private readonly universityFilterProvider: IUniversityFilterProvider;
	private readonly autocomplete: Autocomplete;

	public readonly lastValidSelectionOption = ref("");
	public readonly selectionName = ref("");

	private selection = computed((): string | null => {
		const result = this.filterProvider.getFilterSelection(this.key);
		return result.length === 0 ? null : result[0];
	});

	public constructor(config: IConfig, filterProvider: IFilterProvider,
		universityFilterProvider: IUniversityFilterProvider, eventAggregationService?: EventAggregationService
	) {
		super(FilterKey.ORGANISATIONS, filterProvider, eventAggregationService);

		this.autocomplete = new Autocomplete(config);

		this.filterProvider = filterProvider;
		this.universityFilterProvider = universityFilterProvider;

		watch(this.selection, (value) => void this.onSelectionChanged(value));
	}

	public async processUniversitySelection(universityId: string): Promise<void> {
		const universityPayload = { key: FilterKey.ORGANISATIONS, value: `${universityId}` };

		this.eventAggregationService.publish(new FilterChangeInitiatedEvent([FilterKey.COUNTRY]));
		this.eventAggregationService.publish(new ClearLocationFilterEvent());

		try {
			const filterPayloads = await this.getCountryFilterPayloads(universityId);
			const payload: IFilterPayload[] = [universityPayload, ...filterPayloads];

			await this.filterProvider.processFiltersSelection(payload);
		}
		finally {
			this.eventAggregationService.publish(new FilterChangeCompletedEvent(FilterKeysOfLocation));
		}
	}

	public toggleFilter(): void {
		this.filterProvider.toggleFilterExpandability(FilterKey.ORGANISATIONS);
	}

	public async suggest(query: string): Promise<readonly AutocompleteSuggestion[]> {
		return await this.autocomplete.suggestOrganisations(query);
	}

	public async processSelection(value: string): Promise<void> {
		return await this.processUniversitySelection(value);
	}

	private async onSelectionChanged(selection: string | null): Promise<void> {
		if (selection === null) {
			this.selectionName.value = "";

			this.eventAggregationService.publishTo(ClearHighVisibilityCountryOptionsEvent.EventType,
				new ClearHighVisibilityCountryOptionsEvent());

			return;
		}

		await this.syncWithSelection(selection);
	}

	private async syncWithSelection(selection: string): Promise<void> {
		const universityInfo = await this.universityFilterProvider.retrieveUniversityInfo(selection);
		if (universityInfo === null) {
			return;
		}

		const name = universityInfo.name;
		this.selectionName.value = name;

		this.eventAggregationService.publishTo(ChangeHighVisibilityCountryOptionsEvent.EventType,
			new ChangeHighVisibilityCountryOptionsEvent(universityInfo.valuesOfCountryOptions));
	}

	private async getCountryFilterPayloads(university: string): Promise<IFilterPayload[]> {
		const universityInfo = await this.universityFilterProvider.retrieveUniversityInfo(university);
		if (universityInfo === null) {
			return [];
		}

		this.lastValidSelectionOption.value = universityInfo.name;

		const selectionOfCountries = this.filterProvider.getFilterSelection(FilterKey.COUNTRY);
		const valuesOfCountriesToSelect =
			universityInfo.valuesOfCountryOptions.filter(v => !selectionOfCountries.includes(v));

		return valuesOfCountriesToSelect.map(value => ({ key: FilterKey.COUNTRY, value }));
	}
}
