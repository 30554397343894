<template>
	<section class="DisciplineFilterWrapper"
			 :class="{'is-collapsed': collapsed}"
	>
		<header class="RefineSectionHeading" @click="toggleFilter()">
			<i class="lnr-icons2 FilterIcon"></i>
			<div class="Title">
				Discipline
			</div>
			<span v-if="filterHintCount > 0" class="Badge">
				{{ filterHintCount }}
			</span>
			<i class="ToggleIcon" :class="[collapsed ? 'lnr-chevron-down' : 'lnr-chevron-up']"></i>
		</header>
		<ul class="RefineContentOptionsList">
			<li :class="{'Hidden': !isDisciplineSelected}">
				<button class="ShowMainDisciplines" type="button"
					ref="allDisciplinesButtonRef" @click="removeSelection()"
				>
					<i class="lnr-arrow-left"></i>
					<span>All disciplines</span>
				</button>
				<Tracking v-if="allDisciplinesTrackingData"
					:data="allDisciplinesTrackingData">
				</Tracking>
			</li>

		<template v-for="discipline in disciplineFilterOptions" :key="discipline.id">
			<li v-if="showDiscipline(discipline.optionValue, discipline.parentIdentity)"
				:data-filter="key" class="Discipline"
				:class="{'is-active': discipline.optionValue === selectedDiscipline,
						'Discipline': discipline.parentIdentity === undefined,
						'SubDiscipline': discipline.parentIdentity !== undefined}"
			>
				<DisciplineOption :discipline="discipline" :displayFacets="displayFacets"
					:selectedDiscipline="selectedDiscipline"
				></DisciplineOption>
			</li>
		</template>
		</ul>
	</section>
</template>

<script lang="ts" src="./DisciplineFilter.ts"></script>
<style lang="scss" scoped src="./DisciplineFilter.scss"></style>
