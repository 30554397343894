import { Ref } from "vue";
import { ControlEvent } from "@/models/filter-tracking/ControlEvent";
import { ITrackingInput } from "../../presentation/tracking/ITrackingInput";
import { Action } from "@studyportals/datalake-event-tracker";

export class TrackingInput implements ITrackingInput {

	public readonly elementRef: Ref<Element | null>;
	public readonly dataRef: Ref<string>;
	public readonly action: Ref<Action>;
	public readonly eventToSubscribe: ControlEvent;
	public readonly label: string | undefined;

	public constructor(elementRef: Ref<Element | null>, dataRef: Ref<string>,
		action: Ref<Action>, subscribeTo: ControlEvent, label?: string
	) {
		this.elementRef = elementRef;
		this.dataRef = dataRef;
		this.eventToSubscribe = subscribeTo;
		this.label = label;
		this.action = action;
	}

}
