import { FilterKey } from "@/models/FilterKey";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import Filter from "@/Filter.class";
import { EventAggregationService } from "@/platform/EventAggregationService";

export default class EducationLevelFilter extends Filter {

	public constructor(filterProvider: IFilterProvider, eventAggregationService?: EventAggregationService) {
		super(FilterKey.EDUCATION_LEVEL, filterProvider, eventAggregationService);
	}
}
