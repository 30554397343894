<template>
	<div class="FilterTag" :class="{ Disabled: tag.disabled }"
		@click="tag.clear()" ref="tagRef"
	>
		<span class="Badge" v-if="tag.hasMultipleValues">{{ tag.count }}</span>
		<span class="FilterName">{{ tag.displayName }}</span>
		<i class="lnr-cross ButtonIcon"></i>
	</div>
	<Tracking v-if="trackingData" :data="trackingData"></Tracking>
</template>

<script lang="ts" src="./Tag.ts"></script>
<style lang="scss" src="./Tag.scss" scoped></style>
