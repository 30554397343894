<template>
	<div>
		<label>
			<input ref="checkboxInputRef"
				type="checkbox"
				class="CheckboxInput"
				:value="optionValue"
				:data-filter="filterKey"
				v-model="selectedOptions"
				@click="updateSelection()"
			>
			<Tracking v-if="trackingData" :data="trackingData"></Tracking>
			<span>{{ optionName }}</span>
			<span v-if="displayFacets" class="Facet">{{ facet }}</span>
			<slot name="ExpandCollapseToggle"></slot>
		</label>
	</div>
</template>

<script lang="ts" src="./Checkbox.ts"></script>
<style lang="scss" scoped src="./Checkbox.scss"></style>
