import { computed, ref, Ref } from "vue";
import { CountryFilterOptionInformation } from "@/models/CountryFilterOptionInformation";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { FilterKey } from "@/models/FilterKey";
import { EventAggregationService } from "@/platform/EventAggregationService";
import { ISubscriber } from "@studyportals/event-aggregation-service-interface";
import { ChangeHighVisibilityCountryOptionsEvent } from "./events/ChangeHighVisibilityCountryOptionsEvent";
import { ClearHighVisibilityCountryOptionsEvent } from "./events/ClearHighVisibilityCountryOptionsEvent";
import { EventAggregationServiceSubscription } from "@/platform/EventAggregationServiceSubscription";

export class VisibleCountryOptions implements ISubscriber<ChangeHighVisibilityCountryOptionsEvent>,
	ISubscriber<ClearHighVisibilityCountryOptionsEvent> {
	private readonly eventAggregationServiceSubscription: EventAggregationServiceSubscription;

	private readonly key: FilterKey = FilterKey.COUNTRY;
	private readonly filterProvider: IFilterProvider;
	private readonly locationFilterProvider: ILocationFilterProvider;

	private highVisibilityCountries: Ref<readonly string[]> = ref([]);

	public values = computed((): CountryFilterOptionInformation[] => {
		return this.getCountryInformationFor(this.getUniqueVisibleCountryIds());
	});

	public constructor(filterProvider: IFilterProvider, locationFilterProvider: ILocationFilterProvider,
		eventAggregationService?: EventAggregationService
	) {
		this.filterProvider = filterProvider;
		this.locationFilterProvider = locationFilterProvider;

		eventAggregationService ??= EventAggregationService.instance;
		this.eventAggregationServiceSubscription = eventAggregationService.subscribe(this)
			.to(ChangeHighVisibilityCountryOptionsEvent.EventType, true)
			.to(ClearHighVisibilityCountryOptionsEvent.EventType, true);
	}

	private getUniqueVisibleCountryIds(): string[] {
		const topCountryIds = this.locationFilterProvider.identifyTopCountries();
		const selectedCountries = this.filterProvider.getFilterSelection(this.key);

		const visibleCountries = [...topCountryIds, ...selectedCountries, ...this.highVisibilityCountries.value];
		const uniqueCountries = [...new Set(visibleCountries)];

		return uniqueCountries;
	}

	private getCountryInformationFor(countryIds: string[]): CountryFilterOptionInformation[] {
		const visibleCountryOptions: CountryFilterOptionInformation[] = [];

		countryIds.forEach(countryId => {
			const filterOptionInformation = this.filterProvider.getFilterOptionInformationById(this.key, countryId);
			if (filterOptionInformation != null) {
				visibleCountryOptions.push(filterOptionInformation as CountryFilterOptionInformation);
			}
		})

		return visibleCountryOptions;
	}

	public notify(event: ChangeHighVisibilityCountryOptionsEvent | ClearHighVisibilityCountryOptionsEvent): void {
		if (ChangeHighVisibilityCountryOptionsEvent.is(event)) {
			this.highVisibilityCountries.value = event.optionValues;
		}

		if (ClearHighVisibilityCountryOptionsEvent.is(event)) {
			this.highVisibilityCountries.value = [];
		}
	}

	public dispose(): void {
		this.eventAggregationServiceSubscription.dispose();
	}
}
