import { FilterOptionInformation } from "./FilterOptionInformation";

export class AreaFilterOptionInformation extends FilterOptionInformation {

	public constructor(
		public readonly optionName: string,
		public readonly optionValue: string,
		public readonly optionPath: string,
		public readonly optionTitle: string,
		public readonly countryIdentity: string
	) {
		super(optionName, optionValue, optionPath, optionTitle);
	}
}
