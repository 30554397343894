import { Ref, ref } from "vue";

export class BusyIndicator {

    private counterOfOngoingChanges: number = 0;
    public readonly isBusy: Ref<boolean> = ref(false);

    public reportChange(): void {
        this.counterOfOngoingChanges++;
        this.isBusy.value = true;
    }

    public reportChangeCompleted(): void {
        this.counterOfOngoingChanges--;
        this.isBusy.value = this.counterOfOngoingChanges > 0;
    }
}
