import { computed, Ref, ref, watch, watchEffect, nextTick } from "vue";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterKey } from "@/models/FilterKey";
import { ICurrencyFilterProvider } from "@/interfaces/filter-providers/ICurrencyFilterProvider";
import { IFilterPayload } from "@/interfaces/IFilterPayload";
import { TuitionFeeInterval } from "./TuitionFeeInterval";
import Filter from "@/Filter.class";
import { Currency } from "./Currency";
import { ITrackingInput } from "../tracking/ITrackingInput";
import { EventAggregationService } from "@/platform/EventAggregationService";

export default class TuitionFeeFilter extends Filter {

	private readonly defaultInterval = "[0,-1]";
	private debounceTimeout?: NodeJS.Timeout;
	private debounceTime = 0;

	public fromSliderRef: Ref<HTMLInputElement | null> = ref(null);
	public toSliderRef: Ref<HTMLInputElement | null> = ref(null);
	public fromInputRef: Ref<HTMLInputElement | null> = ref(null);
	public toInputRef: Ref<HTMLInputElement | null> = ref(null);

	public showSliders = ref(true)
	public maxValue = ref(50000)
	public toSelected = ref(this.maxValue.value);
	public fromSelected = ref(0);

	public readonly currency: Currency;
	public readonly fromInputTrackingData: ITrackingInput;
	public readonly toInputTrackingData: ITrackingInput;
	public readonly fromSliderTrackingData: ITrackingInput;
	public readonly toSliderTrackingData: ITrackingInput;

	public constructor(
		public readonly filterProvider: IFilterProvider,
		readonly currencyFilterProvider: ICurrencyFilterProvider,
		eventAggregationService?: EventAggregationService
	) {
		super(FilterKey.TUITION_FEE, filterProvider, eventAggregationService);
		this.currency = new Currency(currencyFilterProvider);
	}

	private shouldShowDecimals = computed(() => {
		return 50000 / this.maxValue.value > 1000
	})

	public sliderStepSize = computed(() => {
		if (this.shouldShowDecimals.value) {
			return 0.01
		}

		return 1
	})

	public fromSelectedFormatted = computed({
		get: () => {
			if (this.shouldShowDecimals.value) {
				return this.fromSelected.value.toString()
			}
			return new Intl.NumberFormat().format(this.fromSelected.value)
		},
		set: (newVal: string) => {
			const parsedValue = parseFloat(newVal.replace(/\D/g, ''));
			this.fromSelected.value = isNaN(parsedValue) ? 0 : parsedValue;
		}
	})

	public toSelectedFormatted = computed({
		get: () => {
			if (this.toSelected.value === this.maxValue.value) {
				return `No Max`
			}

			if (this.shouldShowDecimals.value) {
				return this.toSelected.value.toString()
			}

			return new Intl.NumberFormat().format(this.toSelected.value)
		},
		set: (newVal: string) => {
			const parsedValue = parseFloat(newVal.replace(/\D/g, ''));
			this.toSelected.value = isNaN(parsedValue) ? this.maxValue.value : parsedValue;
		}
	})

	public selection = computed(() => {
		return this.filterProvider.getFilterSelection(this.key)[0] || this.defaultInterval;
	});

	private maxAllowedValue = computed(() => {
		return this.roundValue(this.toSelected.value - (this.maxValue.value * 0.05));
	})

	private minAllowedValue = computed(() => {
		return this.roundValue(this.fromSelected.value + (this.maxValue.value * 0.05))
	})

	public async onMounted(): Promise<void> {

		watch(this.currency.userCurrency, () => this.processCurrencyUpdated(this.maxValue, this.toSelected, this.fromSelected));

		if (this.currency.isAvailable.value) {
			await this.initializeFilter();
			return;
		}

		watch(this.currency.isAvailable, async (isCurrencyAvailable) => {
			if (!isCurrencyAvailable) {
				return;
			}
			await this.initializeFilter();
		})
	}

	private async initializeFilter(): Promise<void> {

		watchEffect(() => void this.setFilterValues());

		await this.processCurrencyUpdated(this.maxValue, this.toSelected, this.fromSelected);
	}

	public debounce(callbackFunction: () => void): void {

		if (this.debounceTimeout) {
			clearTimeout(this.debounceTimeout);
		}

		this.debounceTimeout = setTimeout(() => {
			if (this.debounceTime < Date.now()) {
				callbackFunction();
				this.debounceTime = Date.now() + 400;
			}
		}, 500)
	}

	public async processCurrencyUpdated(max: Ref<number>, to: Ref<number>, from: Ref<number>): Promise<void> {

		if (!this.currency.isChanged()) {
			return;
		}

		const convertedMaxValue = await this.currency.convertFromOriginalToUserCurrency(max.value);
		const convertedToSelected = await this.currency.convertFromOriginalToUserCurrency(to.value);
		const convertedFromSelected = await this.currency.convertFromOriginalToUserCurrency(from.value);

		this.currency.updateOriginal();

		max.value = this.roundValue(convertedMaxValue);

		await nextTick(() => {
			to.value = this.roundValue(convertedToSelected)
			from.value = this.roundValue(convertedFromSelected)
		})
	}

	private roundValue(unrounded: number): number {
		if (this.shouldShowDecimals.value) {
			return Math.round(unrounded * 100) / 100

		}

		return Math.round(unrounded)
	}

	private async setFilterValues(): Promise<void> {

		try {

			const interval = new TuitionFeeInterval(this.selection.value);

			let fromVal = interval.min;
			let toVal = interval.max;

			if (toVal === -1) {
				toVal = this.maxValue.value
			}

			// Filters are always saved in EUR, convert to user currency
			fromVal = await this.currency.convertFromEurToUserCurrency(fromVal);
			toVal = await this.currency.convertFromEurToUserCurrency(toVal);

			this.fromSelected.value = this.roundValue(fromVal);
			this.toSelected.value = this.roundValue(toVal);

			this.updateFromSliderValue();
			this.updateToSliderValue();
		}
		catch {
			console.warn('Unable to set initial Tuition Fee filter')
		}
	}

	private fillSlider(): void {
		if (!this.fromSliderRef.value || !this.toSliderRef.value) {
			return;
		}

		const toMin = Number(this.toSliderRef.value.min);
		const toMax = Number(this.toSliderRef.value.max);

		const rangeDistance = toMax - toMin;
		const fromPosition = this.fromSelected.value - toMin;
		const toPosition = this.toSelected.value - toMin;

		const sliderColor = '#B8C6CE'; //$GreyL
		const rangeColor = '#3F5C6E'; //$GreyD

		this.toSliderRef.value.style.background = `linear-gradient(
		  to right,
		  ${sliderColor} 0%,
		  ${sliderColor} ${(fromPosition) / (rangeDistance) * 100}%,
		  ${rangeColor} ${((fromPosition) / (rangeDistance)) * 100}%,
		  ${rangeColor} ${(toPosition) / (rangeDistance) * 100}%, 
		  ${sliderColor} ${(toPosition) / (rangeDistance) * 100}%, 
		  ${sliderColor} 100%)`;
	}

	private async setSelectedOptions(): Promise<void> {

		let convertedFromVal = this.fromSelected.value;
		let convertedToVal = this.toSelected.value;

		// Filters are always saved in EUR, convert from user currency
		convertedFromVal = await this.currency.convertFromUserCurrencyToEur(this.fromSelected.value);
		convertedToVal = await this.currency.convertFromUserCurrencyToEur(this.toSelected.value);

		const toValue = Number(this.toSelected.value) === this.maxValue.value ? -1 : Math.round(convertedToVal);
		const options = `[${Math.round(convertedFromVal)},${toValue}]`;

		const interval = new TuitionFeeInterval(options);
		if (interval.isDefault) {
			await this.filterProvider.clearFilter(this.key);
			return;
		}

		await this.filterProvider.processFilterSelection({
			key: this.key,
			value: options
		} as IFilterPayload);
	}

	private setAndTrackOptions(inputType: string): void {
		this.debounce(() => {
			void this.setSelectedOptions()
			this.currency.track(inputType)
		});
	}

	public updateFromInputValue(event: Event): void {
		const target = event.target as HTMLInputElement;
		this.fromSelectedFormatted.value = target.value;

		this.updateFromSliderValue(event);
		this.setAndTrackOptions('text');
	}

	public updateToInputValue(event: Event): void {
		const target = event.target as HTMLInputElement;
		this.toSelectedFormatted.value = target.value;

		this.updateToSliderValue(event);
		this.setAndTrackOptions('text');
	}

	public updateFromSliderValue(event?: Event): void {

		const fromSelected = Number(this.fromSelected.value);

		if (fromSelected >= this.maxAllowedValue.value) {
			event?.preventDefault();

			const newFrom = this.maxAllowedValue.value;
			this.fromSelected.value = newFrom <= 0 ? 0 : newFrom
		}

		this.fillSlider()
	}

	public updateToSliderValue(event?: Event): void {

		let toSelected = Number(this.toSelected.value);

		if (toSelected >= this.maxValue.value) {
			toSelected = this.maxValue.value
			this.toSelected.value = toSelected;
		}

		if (this.minAllowedValue.value >= toSelected) {
			event?.preventDefault();

			this.toSelected.value = this.minAllowedValue.value;
		}

		this.fillSlider();
	}

	public changeCurrencyClicked(): void {
		this.currency.changeCurrency();
	}
}
