import { IFilterProvider } from "../../../interfaces/filter-providers/IFilterProvider";
import { ILocationFilterProvider } from "../../../interfaces/filter-providers/ILocationFilterProvider";
import { CountryExpandability } from "./CountryExpandability";
import { CountryFilterSelection } from "./CountryFilterSelection";

export class CountryFilterContext {
	public static readonly key = 'countryFilterContext';

	public readonly filterProvider: IFilterProvider;
	public readonly locationFilterProvider: ILocationFilterProvider;
	public readonly expandability: CountryExpandability;
	public readonly countryFilterSelection: CountryFilterSelection;

	public constructor(filterProvider: IFilterProvider,	locationFilterProvider: ILocationFilterProvider,
		expandability: CountryExpandability, countryFilterSelection: CountryFilterSelection
	) {

		this.filterProvider = filterProvider;
		this.locationFilterProvider = locationFilterProvider;
		this.expandability = expandability;
		this.countryFilterSelection = countryFilterSelection;
	}
}