import { Ref } from "vue";
import { FilterKey } from "@/models/FilterKey";
import { AreaFilterOptionInformation } from "@/models/AreaFilterOptionInformation";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { CountryExpandability } from "@/presentation/location/country/CountryExpandability";
import { FilterBase } from "@/FilterBase.class";
import { EventAggregationService } from "@/platform/EventAggregationService";

export class AreaFilter extends FilterBase {
	public readonly countryId: string;
	public readonly areaFilterOptions: AreaFilterOptionInformation[];

	public readonly showArea: Ref<boolean>;

	public constructor(countryId: string, private filterProvider: IFilterProvider,
		private countryExpandability: CountryExpandability, eventAggregationService?: EventAggregationService
	) {
		super(FilterKey.AREA, eventAggregationService);

		this.countryId = countryId;
		this.showArea = this.countryExpandability.isExpandedFor(countryId);

		const areas = this.filterProvider.getFilterOptionInformation(this.key) as AreaFilterOptionInformation[];
		this.areaFilterOptions = areas.sort((a, b) => a.optionName.localeCompare(b.optionName));
	}

	public isAreaSelected(area: string): boolean {
		return this.filterProvider.getFilterSelection(this.key).includes(area);
	}
}
