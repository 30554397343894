import { defineComponent, inject, Component } from "vue";

import { CountryCheckbox } from "./CountryCheckbox.class";
import { CountryCheckboxOptions } from "@/interfaces/ICountryCheckboxOptions";
import { CountryFilterContext } from "@/presentation/location/country/CountryFilterContext";
import Tracking from "@/presentation/tracking/Tracking.vue";

export default defineComponent({
	components: {
		Tracking: Tracking as Component
	},
	props: {
		optionName: String,
		optionValue: String,
		hasAreas: Boolean
	},
	setup: (props: any) => {
		const context: CountryFilterContext | undefined = inject(CountryFilterContext.key);
		if (context == null) {
			throw new Error(`"${CountryFilterContext.key}" class was not provided for CountryCheckbox`);
		}

		const countryCheckboxOptions = props as CountryCheckboxOptions;
		const component = new CountryCheckbox(
			countryCheckboxOptions.optionName,
			countryCheckboxOptions.optionValue,
			countryCheckboxOptions.hasAreas,
			context.filterProvider,
			context.locationFilterProvider,
			context.expandability,
			context.countryFilterSelection
		);

		return {
			facet: component.facet,
			selectedOptions: component.selectedOptions,
			expandCheckbox: component.expandCheckbox,
			filterKey: component.filterKey,
			displayFacets: component.displayFacets,
			checkboxInputRef: component.checkboxInputRef,
			trackingData: component.trackingData,
			updateSelection: component.updateSelection.bind(component),
			toggleExpandedAreas: component.toggleExpandedAreas.bind(component)
		}
	}
});
