import { FilterOptionInformation } from "./FilterOptionInformation";

export class CountryFilterOptionInformation extends FilterOptionInformation {
	public constructor(
		public readonly optionName: string,
		public readonly optionValue: string,
		public readonly optionPath: string,
		public readonly optionTitle: string,
		public readonly hasAreas: boolean,
		public readonly eea: boolean
	) {
		super(optionName, optionValue, optionPath, optionTitle);
	}
}
