import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterKey } from "@/models/FilterKey";
import { OrganisationTag } from "@/models/tags/OrganisationTag";
import { Tag } from "@/models/tags/Tag";
import { TuitionFeeTag } from "@/models/tags/TuitionFeeTag";

export class TagsFactory {

	private static readonly MAX_NUMBER_OF_INDIVIDUAL_TAGS = 9;

	private readonly eligibleKeysForTags = [
		FilterKey.ORGANISATIONS,
		FilterKey.DISCIPLINES,
		FilterKey.EDUCATION_LEVEL,
		FilterKey.CONTINENT,
		FilterKey.COUNTRY,
		FilterKey.AREA,
		FilterKey.TUITION_FEE,
		FilterKey.DURATION,
		FilterKey.ATTENDANCE,
		FilterKey.DELIVERY_METHOD,
		FilterKey.DEGREE_TYPE,
		FilterKey.EDUCATIONAL_FORM,
		FilterKey.SPECIAL_PROGRAMMES
	]

	public constructor(private filterProvider: IFilterProvider) {}

	public constructFor(key: FilterKey): Tag[] {

		if (!this.eligibleKeysForTags.includes(key)) {
			return [];
		}

		const tags: Tag[] = [];
		const required = this.filterProvider.isFilterRequired(key);
		const values = this.filterProvider.getFilterSelection(key);

		const disabled = this.isDisabled(required, values.length);

		if (this.hasMoreValuesThanMaxAllowed(values.length)) {
			tags.push(this.construct(key, values, required, disabled));
			return tags;
		}

		values.forEach(value => {
			tags.push(this.construct(key, [value], required, disabled));
		});

		return tags;
	}

	public construct(key: FilterKey, values: readonly string[], required: boolean, disabled: boolean): Tag {
		if (key === FilterKey.TUITION_FEE) {
			return new TuitionFeeTag(values, required, disabled, this.filterProvider);
		}
		if (key === FilterKey.ORGANISATIONS) {
			return new OrganisationTag(values, required, disabled, this.filterProvider);
		}
		return new Tag(key, values, required, disabled, this.filterProvider);
	}

	private hasMoreValuesThanMaxAllowed(valueCount: number): boolean {
		return valueCount > TagsFactory.MAX_NUMBER_OF_INDIVIDUAL_TAGS;
	}

	private isDisabled(required: boolean, valueCount: number): boolean {
		return required && valueCount === 1;
	}
}
