import { computed, ref } from "vue";
import { Tag } from "../../models/tags/Tag";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { ClearFilters } from "./ClearFilters";
import { TagContainer } from "./TagContainer";
import { TagsFactory } from "./TagsFactory";
import { ISubscriber } from "@studyportals/event-aggregation-service-interface";
import { FilterChangeInitiatedEvent } from "@/events/FilterChangeInitiatedEvent";
import { FilterChangeCompletedEvent } from "@/events/FilterChangeCompletedEvent";
import { EventAggregationService } from "@/platform/EventAggregationService";
import { EventAggregationServiceSubscription } from "@/platform/EventAggregationServiceSubscription";
import { BusyIndicator } from "@/BusyIndicator";
import { PerformDebounce } from "../../platform/PerformDebounce";

export default class SelectedFilters implements ISubscriber<FilterChangeInitiatedEvent>,
	ISubscriber<FilterChangeCompletedEvent>
{
	public readonly tagContainer: TagContainer;
	public readonly busyIndicator: BusyIndicator;
	public clearAllButtonRef = ref<HTMLElement | null>(null);

	private readonly tagFactory: TagsFactory;
	private readonly clearFilters: ClearFilters;
	private readonly eventAggregationServiceSubscription: EventAggregationServiceSubscription;
	private readonly debounceClearAll: ReturnType<PerformDebounce["for"]>;

	public constructor(private filterProvider: IFilterProvider) {
		this.busyIndicator = new BusyIndicator();

		this.tagContainer = new TagContainer();
		this.tagFactory = new TagsFactory(filterProvider);
		this.clearFilters = new ClearFilters(filterProvider);

		this.eventAggregationServiceSubscription = EventAggregationService.instance.subscribe(this)
		.to(FilterChangeInitiatedEvent.EventType)
		.to(FilterChangeCompletedEvent.EventType);

		this.debounceClearAll = new PerformDebounce()
			.for(() => void this.clearFilters.clearAll(this.tagFilterKeys.value), 100);
	}

	public onMounted(): void {
		this.tagContainer.onMounted();
	}

	public tags = computed(() => {
		return this.constructTags();
	})

	public removableFiltersCount = computed(() => {
		return this.tags.value.reduce((acc, tag) => acc + tag.count, 0);
	})

	public filtersApplied = computed(() => {
		return this.tags.value.length > 0;
	})

	public tagFilterKeys = computed(() => {
		return this.tags.value
			.filter(tag => !tag.required)
			.map(tag => tag.key);
	})

	public stringOfUniqueTagFilterKeys = computed(() => {
		return [...new Set(this.tagFilterKeys.value)].join(",");
	})

	public async notify(event: FilterChangeInitiatedEvent | FilterChangeCompletedEvent): Promise<void>
	{
		if (FilterChangeInitiatedEvent.is(event)) {
			this.busyIndicator.reportChange();
		}

		if (FilterChangeCompletedEvent.is(event)) {
			this.busyIndicator.reportChangeCompleted();
		}
	}

	public clearAll = (): Promise<void> => {
		this.debounceClearAll();

		return Promise.resolve();
	}

	private constructTags(): Tag[] {
		const selectedKeys = this.filterProvider.getFilterKeySelection();
		const tags: Tag[] = [];

		selectedKeys.forEach(key => {
			tags.push(...this.tagFactory.constructFor(key));
		})

		return tags;
	}

	public onUnmounted(): void {
		this.tagContainer.dispose();
		this.clearFilters.dispose();
		this.eventAggregationServiceSubscription.dispose();
	}
}
