import { Component, defineComponent, onUnmounted } from "vue";

import ContinentFilter from "./continent/ContinentFilter.vue";
import CountryFilter from "./country/CountryFilter.vue";
import LocationFilter from "./LocationFilter.class";
import LocationSkeletonLoader from "./skeleton-loader/LocationSkeletonLoader.vue";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterProviderKey } from "@/platform/FilterProviderKey";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { LocationFilterType } from "@/models/LocationFilterType";
import { InjectExternal } from "../../platform/InjectExternal";

export default defineComponent({
	components: {
		ContinentFilter: ContinentFilter as Component,
		CountryFilter: CountryFilter as Component,
		LocationSkeletonLoader: LocationSkeletonLoader as Component
	},
	setup: () => {

		const filterProvider = InjectExternal<IFilterProvider>(FilterProviderKey.FILTER_PROVIDER);
		const locationFilterProvider = InjectExternal<ILocationFilterProvider>(FilterProviderKey.LOCATION_FILTER_PROVIDER);

		const component = new LocationFilter(filterProvider, locationFilterProvider);
		onUnmounted(() => component.dispose());

		return {
			collapsed: component.collapsed,
			countryFilterHintCount: component.countryFilterHintCount,
			continentFilterHintCount: component.continentFilterHintCount,
			activeLocationFilterType: component.activeLocationFilterType,
			isBusy: component.busyIndicator.isBusy,
			toggleContinentFilter: component.toggleContinentFilter.bind(component),
			toggleCountryFilter: component.toggleCountryFilter.bind(component),
			toggleFilter: component.toggleFilter.bind(component),
			switchTabs: component.switchTabs.bind(component),
			includeContinents: locationFilterProvider.includeContinents,
			LocationFilterType,
			filterProvider
		}
	}
});
