import { defineComponent, toRef, PropType, Ref, ref, watch, Component } from "vue";
import { MultiSelect } from "@studyportals/multiselect";
import { Action } from "@studyportals/datalake-event-tracker";
import { ITrackingInput } from "../ITrackingInput";
import { ControlEvent } from "../../../models/filter-tracking/ControlEvent";
import { OnSelectEvent } from "@/interfaces/IOnSelectEvent";
import Tracking from "@/presentation/tracking/Tracking.vue";
import { FilterKey } from "@/models/FilterKey";
import { TrackingInput } from "@/models/filter-tracking/TrackingInput";

export default defineComponent({
	components: {
		MultiSelect,
		Tracking: Tracking as Component
	},
	props: {
		filterKey: { type: String as PropType<FilterKey>, required: false },
		options: { type: Array, default: [] },
		label: { type: String, default: '' },
		placeholder: { type: String, default: '' },
		entity: { type: String, default: '' },
		validationLabel: { type: String, default: '' },
		helperMessage: { type: String, default: '' },
		multipleOptions: { type: Boolean, default: false },
		searchable: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		valid: { type: Boolean, default: true },
		groups: { type: Array, default: [] }
	},
	emits: ['updated'],
	setup: (props, context) => {
		const refOfTrackingProxy: Ref<Element | null> = ref(null);
		const refOfTrackingData: Ref<string> = ref("");

		const onUpdated = (event: OnSelectEvent): void => {
			refOfTrackingData.value = event.value;

			if (refOfTrackingProxy.value) {
				refOfTrackingProxy.value.dispatchEvent(new Event(ControlEvent.CHANGE));
			}

			context.emit('updated', event);
		};

		const refOfTrackingInput: Ref<ITrackingInput | null> = ref(null);
		const maintainTrackingInput = (): void => {
			refOfTrackingInput.value = new TrackingInput(refOfTrackingProxy,
				refOfTrackingData, ref(Action.SELECT),
				ControlEvent.CHANGE, props.filterKey);
		};
		watch(toRef(props, "filterKey"), () => maintainTrackingInput());
		maintainTrackingInput();

		return {
			options: toRef(props, 'options'),
			label: toRef(props, 'label'),
			placeholder: toRef(props, 'placeholder'),
			entity: toRef(props, 'entity'),
			validationLabel: toRef(props, 'validationLabel'),
			helperMessage: toRef(props, 'helperMessage'),
			multipleOptions: toRef(props, 'multipleOptions'),
			searchable: toRef(props, 'searchable'),
			disabled: toRef(props, 'disabled'),
			valid: toRef(props, 'valid'),
			groups: toRef(props, 'groups'),
			onUpdated,
			trackingData: refOfTrackingInput,
			refOfTrackingProxy
		}
	}
});
