export type AutocompleteSuggestion = {
	readonly id: number;
	readonly name: string;
};

const isAutocompleteSuggestion = (value: unknown): value is AutocompleteSuggestion => {
	return typeof value === "object" && value !== null && "id" in value && "name" in value;
};

const isAutocompleteSuggestions = (value: unknown): value is readonly AutocompleteSuggestion[] => {
	return Array.isArray(value) && value.every(isAutocompleteSuggestion);
}

export {
	isAutocompleteSuggestion,
	isAutocompleteSuggestions
}
