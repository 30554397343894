import { IConfig } from "../platform/IConfig";
import { AutocompleteSuggestion, isAutocompleteSuggestions } from "./AutocompleteSuggestion";

export class Autocomplete {
	private readonly configuration: IConfig;

	public constructor(configuration: IConfig) {
		this.configuration = configuration;
	}

	public async suggestOrganisations(query: string): Promise<readonly AutocompleteSuggestion[]> {
		const queryEncoded = encodeURIComponent(query);

		const context = this.configuration.autocompleteContext ?? "lv-bachelor,master,preparation,phd,short";
		const type = "organisation";
		const url = `${this.configuration.autocompleteApiUrl}?keyword=${queryEncoded}&type=${type}&context=${context}`;

		const response = await fetch(url);
		if (response.ok) {
			const suggestions = await response.json() as unknown;
			if (isAutocompleteSuggestions(suggestions)) {
				return suggestions;
			}
		}

		return [];
	}
}
