<template>
	<div ref="disciplineOptionRef"
		@click="selectOption(discipline)"
		:class="{'is-active': discipline.optionValue === selectedDiscipline,
			'MainDiscipline': discipline.parentIdentity === undefined,
			'SubDiscipline': discipline.parentIdentity !== undefined}"
	>
		<i class="DisciplineIcon"
			v-if="discipline.parentIdentity === undefined"
			:class="getIconOf(discipline)"
		></i>
		<span class="DisciplineName">{{ discipline.optionName }}</span>
		<span v-if="displayFacets" class="Facet">{{ facet(discipline.optionValue) }}</span>
	</div>
	<Tracking v-if="trackingData" :data="trackingData"></Tracking>
</template>

<script lang="ts" src="./DisciplineOption.ts"></script>
<style lang="scss" scoped src="./DisciplineOption.scss"></style>
