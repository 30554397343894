import { defineComponent, onMounted, onUnmounted, Component } from "vue";

import TuitionFeeFilter from "./TuitionFeeFilter.class";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterProviderKey } from "@/platform/FilterProviderKey";
import { ICurrencyFilterProvider } from "@/interfaces/filter-providers/ICurrencyFilterProvider";
import { InjectExternal } from "../../platform/InjectExternal";
import Tracking from "@/presentation/tracking/Tracking.vue";
import { TuitionFeeFilterTracking } from "./TuitionFeeFilter.tracking";

export default defineComponent({
	components: {
		Tracking: Tracking as Component
	},
	setup: () => {

		const filterProvider = InjectExternal<IFilterProvider>(FilterProviderKey.FILTER_PROVIDER);
		const currencyFilterProvider = InjectExternal<ICurrencyFilterProvider>(FilterProviderKey.CURRENCY_FILTER_PROVIDER);

		const component = new TuitionFeeFilter(filterProvider, currencyFilterProvider);

		const tracking = new TuitionFeeFilterTracking(component);

		onMounted(component.onMounted.bind(component));
		onUnmounted(component.dispose.bind(component));

		return {
			key: component.key,
			filterOptions: component.filterOptions,
			collapsed: component.collapsed,
			filterHintCount: component.filterHintCount,
			toSelected: component.toSelected,
			fromSelected: component.fromSelected,
			maxValue: component.maxValue,
			userCurrency: component.currency.userCurrency,
			fromSelectedFormatted: component.fromSelectedFormatted,
			toSelectedFormatted: component.toSelectedFormatted,
			fromSliderRef: component.fromSliderRef,
			toSliderRef: component.toSliderRef,
			fromInputRef: component.fromInputRef,
			toInputRef: component.toInputRef,
			trackingDataForFromInput: tracking.trackingDataForFromInput,
			trackingDataForToInput: tracking.trackingDataForToInput,
			trackingDataForFromSlider: tracking.trackingDataForFromSlider,
			trackingDataForToSlider: tracking.trackingDataForToSlider,
			sliderStepSize: component.sliderStepSize,
			toggleFilter: component.toggleFilter.bind(component),
			changeCurrencyClicked: component.changeCurrencyClicked.bind(component),
			updateFromSliderValue: component.updateFromSliderValue.bind(component),
			updateToSliderValue: component.updateToSliderValue.bind(component),
			updateFromInputValue: component.updateFromInputValue.bind(component),
			updateToInputValue: component.updateToInputValue.bind(component)
		}
	}
});
