<template>
	<section
	class="AttendanceFilterWrapper"
	:class="{ 'is-collapsed': collapsed }"
	>
	<header class="RefineSectionHeading" @click="toggleFilter()">
		<i class="lnr-apartment FilterIcon"></i>
		<div class="Title">Attendance</div>
		<span v-if="filterHintCount > 0" class="Badge">
		{{ filterHintCount }}
		</span>
		<i
		class="ToggleIcon"
		:class="[collapsed ? 'lnr-chevron-down' : 'lnr-chevron-up']"
		></i>
	</header>

	<ul class="RefineContentOptionsList">
		<li v-for="(item, index) in filterOptions" :key="index">
			<Checkbox :filterKey="key"
				:optionName="item.optionName"
				:optionValue="item.optionValue"
			/>
		</li>
	</ul>

	</section>
</template>

<script lang="ts" src="./AttendanceFilter.ts"></script>
