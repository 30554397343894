import { computed } from "vue";

import { FilterKey } from "@/models/FilterKey";
import { IOption } from "@studyportals/multiselect/src/interfaces/options.interface";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { AreaFilterSelection } from "../area/AreaFilterSelection";
import { VisibleCountryOptions } from "./VisibleCountryOptions";
import { CountryDropdown } from "./CountryDropdown";
import { FilterBase } from "@/FilterBase.class";
import { EventAggregationService } from "@/platform/EventAggregationService";

export class CountryFilter extends FilterBase {
	public readonly visibleCountryOptions: VisibleCountryOptions;

	public countryDropdown: CountryDropdown;

	public constructor(private readonly filterProvider: IFilterProvider,
		private readonly locationFilterProvider: ILocationFilterProvider,
		eventAggregationService?: EventAggregationService
	) {
		super(FilterKey.COUNTRY, eventAggregationService);

		this.countryDropdown = new CountryDropdown(filterProvider);
		this.visibleCountryOptions = new VisibleCountryOptions(filterProvider,
			locationFilterProvider);
	}

	public transformedCountryFormat = computed((): IOption[] => {
		const filterOptionInformation = this.filterProvider.getFilterOptionInformation(this.key);
		return filterOptionInformation.map(countryFilterOption => {
			return {
				label: countryFilterOption.optionName,
				value: countryFilterOption.optionValue
			}
		})
	});

	public async processAreaFilterChange(value: string): Promise<void> {
		const areaFilterSelection = new AreaFilterSelection(this.filterProvider, this.locationFilterProvider);
		return await areaFilterSelection.process(value);
	}

	public override dispose(): void {
		super.dispose();
		this.visibleCountryOptions.dispose();
	}
}
