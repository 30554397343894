<template>
  <ul class="AreaFilterWrapper">
    <template v-for="item in areaFilterOptions" :key="item.optionValue">
      <li
        v-if="
          item.countryIdentity === countryId &&
          (showArea || isAreaSelected(item.optionValue))
        "
      >
        <AreaCheckbox :filterKey="key"
          :optionName="item.optionName"
          :optionValue="item.optionValue"
          @areaFilterChange="$emit('areaFilterChange', $event)"
        />
      </li>
    </template>
  </ul>
</template>

<script lang="ts" src="./AreaFilter.ts"></script>
<style lang="scss" scoped src="./AreaFilter.scss"></style>
