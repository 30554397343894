import { defineComponent, Component, onUnmounted } from "vue";
import { InjectExternal } from "../../platform/InjectExternal";
import { IConfig } from "../../platform/IConfig";
import { FilterProviderKey } from "../../platform/FilterProviderKey";
import { UniversityFilter } from "./UniversityFilter.class";
import { IFilterProvider } from "../../interfaces/filter-providers/IFilterProvider";
import { IUniversityFilterProvider } from "../../interfaces/filter-providers/IUniversityFilterProvider";
import DropdownFilter from "../dropdown-filter/DropdownFilter.vue";

export default defineComponent({
	components: {
		DropdownFilter: DropdownFilter as Component
	},
	setup: () => {

		const configuration = InjectExternal<IConfig>(FilterProviderKey.CONFIGURATION);
		const filterProvider = InjectExternal<IFilterProvider>(FilterProviderKey.FILTER_PROVIDER);
		const universityFilterProvider = InjectExternal<IUniversityFilterProvider>(FilterProviderKey.UNIVERSITY_FILTER_PROVIDER);

		const component = new UniversityFilter(configuration, filterProvider, universityFilterProvider);
		onUnmounted(component.dispose.bind(component));

		return {
			collapsed: component.collapsed,
			filterHintCount: component.filterHintCount,
			lastValidSelectionOption: component.lastValidSelectionOption,
			selectionName: component.selectionName,
			key: component.key,
			toggleFilter: component.toggleFilter.bind(component),
			suggest: component.suggest.bind(component),
			processSelection: component.processSelection.bind(component)
		}
	}
});
