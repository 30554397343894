import { defineComponent, toRef, PropType, onMounted, toRaw } from "vue";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterProviderKey } from "@/platform/FilterProviderKey";
import { ITrackingInput } from "./ITrackingInput";
import { InjectExternal } from "@/platform/InjectExternal";
import Tracking from "./Tracking.class";

export default defineComponent({
	props: {
		data: {
			type: Object as PropType<ITrackingInput>,
			required: true
		}
	},
	setup: (props) => {

		const filterProvider = InjectExternal<IFilterProvider>(FilterProviderKey.FILTER_PROVIDER);

		const input = toRaw(props.data);
		if (input) {
			const component = new Tracking(filterProvider.trackedProduct,
				toRef(input, "elementRef"), toRef(input, "dataRef"),
				toRef(input, "action"), input.label, input.eventToSubscribe);

			onMounted(component.onMounted.bind(component));
		}

		return {};
	}
});
