import { defineComponent } from 'vue';
import { InjectExternal } from "../../../platform/InjectExternal";
import { ILocationFilterProvider } from '../../../../src/interfaces/filter-providers/ILocationFilterProvider';
import { IFilterProvider } from '../../../../src/interfaces/filter-providers/IFilterProvider';
import { FilterProviderKey } from '../../../platform/FilterProviderKey';
import { VisibleCountryOptions } from '../country/VisibleCountryOptions';

export default defineComponent({
	setup() {

		const filterProvider = InjectExternal<IFilterProvider>(FilterProviderKey.FILTER_PROVIDER);
		const locationFilterProvider = InjectExternal<ILocationFilterProvider>(FilterProviderKey.LOCATION_FILTER_PROVIDER);

		const visibleCountryOptions = new VisibleCountryOptions(filterProvider, locationFilterProvider);

		return {
			includeContinents: locationFilterProvider.includeContinents,
			filterOptions: visibleCountryOptions.values
		}
	},
})
