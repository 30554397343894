import { defineComponent } from 'vue';

export default defineComponent({
	setup() {
		const noOfSkeletonTags = 4;

		return {
			noOfSkeletonTags: noOfSkeletonTags
		};
	}
})
