import { IEvent } from "@studyportals/event-aggregation-service-interface";

export class FiltersDisplayedEvent implements IEvent {
	public static readonly EventType = "SearchFilters:FiltersDisplayedEvent";

	public readonly timestamp = new Date();
	public readonly eventType = FiltersDisplayedEvent.EventType;

	public static is(event: IEvent): event is FiltersDisplayedEvent {
		return event.eventType === FiltersDisplayedEvent.EventType;
	}
}
