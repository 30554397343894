<template>
	<section class="TuitionFeeFilterWrapper"
			 :class="{'is-collapsed': collapsed}"
	>
		<header class="RefineSectionHeading" @click="toggleFilter()">
			<i class="lnr-bag-coins FilterIcon"></i>
			<div class="Title">
				Tuition Fee
			</div>
			<span v-if="filterHintCount > 0" class="Badge">
				{{ filterHintCount }}
			</span>
			<i class="ToggleIcon" :class="[collapsed ? 'lnr-chevron-down' : 'lnr-chevron-up']"></i>
		</header>

		<ul class="RefineContentOptionsList">
			<div class="controlLabel">
				<span><b>Annual tuition fee </b> <a v-on:click="changeCurrencyClicked">{{userCurrency}}</a></span>
			</div>
			<div class="rangeContainer">
				<div class="formControl">

					<div class="SCTextInput">
						<input ref="fromInputRef"
							:value="fromSelectedFormatted"
							@onfocusout="updateFromInputValue"
							@change="updateFromInputValue"
							inputmode="numeric"
						/>
						<Tracking v-if="trackingDataForFromInput" :data="trackingDataForFromInput"></Tracking>
						<label>Min</label>
					</div>

					<div class="SCTextInput">
						<input ref="toInputRef"
							:value="toSelectedFormatted"
							@onfocusout="updateToInputValue"
							@change="updateToInputValue"
							inputmode="numeric"
						/>
						<Tracking v-if="trackingDataForToInput" :data="trackingDataForToInput"></Tracking>
						<label>Max</label>
					</div>

				</div>
				<div class="sliderControl">

					<input ref="fromSliderRef"
						class="fromSlider slider"
						:step="sliderStepSize"
						type="range"
						v-model="fromSelected"
						min="0"
						:max="maxValue"
						@input="updateFromInputValue"
					/>
					<Tracking v-if="trackingDataForFromSlider" :data="trackingDataForFromSlider"></Tracking>

					<input ref="toSliderRef"
						class="toSlider slider"
						:step="sliderStepSize"
						type="range"
						v-model="toSelected"
						min="0" :max="maxValue"
						@input="updateToInputValue"
					/>
					<Tracking v-if="trackingDataForToSlider" :data="trackingDataForToSlider"></Tracking>

				</div>
			</div>
		</ul>
	</section>
</template>

<script lang="ts" src="./TuitionFeeFilter.ts"></script>
<style lang="scss"  src="./TuitionFeeFilter.scss"></style>
