<template>
    <MultiSelect
        :options="options"
        :label="label"
        :placeholder="placeholder"
        :entity="entity"
        :validationLabel="validationLabel"
        :helperMessage="helperMessage"
        :searchable="searchable"
        :disabled="disabled"
        :valid="valid"
        :groups:="groups"
        @updated="onUpdated">
    </MultiSelect>
    
    <div ref="refOfTrackingProxy" />
    <Tracking v-if="trackingData" :data="trackingData"></Tracking>
</template>

<script lang="ts" src="./TrackedMultiSelect.ts"></script>
