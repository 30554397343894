import { ICurrencyFilterProvider } from '@/interfaces/filter-providers/ICurrencyFilterProvider';
import { computed } from 'vue';

export class Currency {

	private readonly euro = 'EUR';
	public original = this.euro as string;

	public constructor(private readonly currencyFilterProvider: ICurrencyFilterProvider) {}

	public isAvailable = computed(() => {
		return this.currencyFilterProvider.getAreDependenciesLoaded()
	});

	public userCurrency = computed(() => {
		return this.currencyFilterProvider.getCurrency()
	})

	public async convertFromOriginalToUserCurrency(value: number): Promise<number> {
		return await this.currencyFilterProvider.currencyConvert(value, this.original, this.userCurrency.value)
	}

	public async convertFromEurToUserCurrency(value: number): Promise<number> {
		return await this.currencyFilterProvider.currencyConvert(value, this.euro, this.userCurrency.value)
	}

	public async convertFromUserCurrencyToEur(value: number): Promise<number> {
		return await this.currencyFilterProvider.currencyConvert(value, this.original, this.euro)
	}

	public changeCurrency(): void {
		this.currencyFilterProvider.requestCurrencyChange();
	}

	public isChanged(): boolean {
		return this.original !== this.userCurrency.value;
	}

	public updateOriginal(): void {
		this.original = this.userCurrency.value;
	}

	public track(inputType: string): void {
		this.currencyFilterProvider.trackCustomClickEvent(`tuition_filter_click`, inputType);
	}
}
