import { computed } from "vue";
import { IFilterProvider } from "./interfaces/filter-providers/IFilterProvider";
import { FilterOptionInformation } from "./models/FilterOptionInformation";
import { FilterKey } from "./models/FilterKey";
import { EventAggregationService } from "./platform/EventAggregationService";
import { FilterBase } from "./FilterBase.class";

export default abstract class Filter extends FilterBase {
	public filterOptions: readonly FilterOptionInformation[];
	public filterProvider: IFilterProvider;

	public collapsed = computed((): boolean => {
		return this.filterProvider.isFilterCollapsed(this.key);
	});

	public filterHintCount = computed((): number => {
		return this.filterProvider.getFilterSelection(this.key).length;
	});

	public constructor(key: FilterKey, filterProvider: IFilterProvider,
		eventAggregationService: EventAggregationService | undefined
	) {
		super(key, eventAggregationService);

		this.filterProvider = filterProvider;
		this.filterOptions = this.filterProvider.getFilterOptionInformation(this.key);
	}

	public toggleFilter(): void {
		this.filterProvider.toggleFilterExpandability(this.key);
	}
}
