import { IEvent, IEventAggregationService, ISubscriber } from "@studyportals/event-aggregation-service-interface";
import { EventAggregationServiceSubscription } from "./EventAggregationServiceSubscription";

export class EventAggregationService implements IEventAggregationService {
	public static readonly instance: EventAggregationService = new EventAggregationService();

	private _target: IEventAggregationService | null;

	public constructor(target?: IEventAggregationService) {
		this._target = target ?? null;
	}

	public subscribeTo(eventType: string, subscriber: ISubscriber<IEvent>, replay?: boolean | undefined): void {
		const target = this.obtainTarget();
		if (target) {
			target.subscribeTo(eventType, subscriber, replay);
		}
	}

	public publishTo(eventType: string, event: IEvent): void {
		const target = this.obtainTarget();
		if (target) {
			target.publishTo(eventType, event);
		}
	}

	public publish(event: IEvent) {
		this.publishTo(event.eventType, event);
	}

	public subscribe(subscriber: ISubscriber<IEvent>): EventAggregationServiceSubscription {
		return new EventAggregationServiceSubscription(this, subscriber);
	}

	private obtainTarget(): IEventAggregationService | null {
		if (this._target !== null) {
			return this._target;
		}

		if (window["EventAggregationService"]) {
			return window["EventAggregationService"] as IEventAggregationService;
		}

		return null;
	}
}
