import { IEvent } from "@studyportals/event-aggregation-service-interface";

export class ChangeHighVisibilityCountryOptionsEvent implements IEvent {
	public static readonly EventType = "SearchFilters:ChangeHighVisibilityCountryOptionsEvent";

	public readonly timestamp = new Date();
	public readonly eventType = ChangeHighVisibilityCountryOptionsEvent.EventType;
	public readonly optionValues: readonly string[];

	public constructor(optionValues: readonly string[]) {
		this.optionValues = optionValues;
	}

	public static is(event: IEvent): event is ChangeHighVisibilityCountryOptionsEvent {
		return event.eventType === ChangeHighVisibilityCountryOptionsEvent.EventType;
	}
}
