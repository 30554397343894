<template>
	<div class="FilterSearchBoxWrapper">
		<input ref="inputRef"
			type="search"
			v-model="input"
			class="FilterSearchBox"
			:placeholder="placeholder"
			autocomplete="off"
			@input="onInput()"
			@blur="onBlur()">
		<i v-if="!input" class="lnr-magnifier FilterSearchBoxIcon"></i>
		<i v-else class="lnr-cross FilterSearchBoxIcon Clickable"
			@mousedown.prevent="onClearInputClick()"
		></i>
	</div>
	<div class="FilterSuggestionsWrapper">
		<p v-for="(item, index) in suggestions"
			:key="index" class="FilterSuggestion"
			@click="onSuggestionChosen(item)"
		>
			{{ item.name }}
		</p>
	</div>

	<div ref="refOfTrackingProxy"></div>
    <Tracking v-if="trackingData" :data="trackingData"></Tracking>
</template>

<script lang="ts" src="./DropdownFilter.ts"></script>
<style lang="scss" src="./DropdownFilter.scss" scoped></style>

