import { computed, ref, Ref } from 'vue';
import { FiltersDisplayedEvent } from '@/events/FiltersDisplayedEvent';
import { EventAggregationService } from '@/platform/EventAggregationService';
import { ISubscriber } from '@studyportals/event-aggregation-service-interface';
import { EventAggregationServiceSubscription } from '@/platform/EventAggregationServiceSubscription';
import { BusyIndicator } from '@/BusyIndicator';


export class TagContainer implements ISubscriber<FiltersDisplayedEvent> {
	private readonly eventAggregationServiceSubscription: EventAggregationServiceSubscription;

	public tagContainerRef: Ref<HTMLElement | null> = ref(null);
	public isTagContainerMoreThanTwoRows = ref(false);
	public isCollapsed = ref(true);

	private observer: MutationObserver;
	private tagContainerMaxHeight = 0;

	public constructor(eventAggregationService?: EventAggregationService) {
		eventAggregationService ??= EventAggregationService.instance;
		this.eventAggregationServiceSubscription = eventAggregationService
			.subscribe(this).to(FiltersDisplayedEvent.EventType, true);
	}

	public dispose(): void {
		this.eventAggregationServiceSubscription.dispose();
		this.observer?.disconnect();
	}

	public notify(event: FiltersDisplayedEvent): void {
		if (FiltersDisplayedEvent.is(event)) {
			this.setIsTagContainerMoreThanTwoRows();
		}
	}

	public onMounted(): void {
		this.tagContainerMaxHeight = this.getTagContainerMaxHeight();
		this.setIsTagContainerMoreThanTwoRows();
		this.initTagContainerObserver();
	}

	public shouldDisplayShowMore = computed(() => {
		return this.isCollapsed.value && this.isTagContainerMoreThanTwoRows.value;
	})

	public shouldDisplayShowLess = computed(() => {
		return !this.isCollapsed.value && this.isTagContainerMoreThanTwoRows.value;
	})

	public showMore = (): void => {
		this.isCollapsed.value = false;
		this.tagContainerRef.value?.classList.remove("Collapsed");
	}

	public showLess = (): void => {
		this.isCollapsed.value = true;
		this.tagContainerRef.value?.classList.add("Collapsed");
	}

	private setIsTagContainerMoreThanTwoRows = (): void => {
		const container = this.tagContainerRef.value;
		if (!container) {
			return;
		}
		this.isTagContainerMoreThanTwoRows.value = this.tagContainerMaxHeight < container.scrollHeight;
	}

	private getTagContainerMaxHeight(): number {
		if (!this.tagContainerRef || !this.tagContainerRef.value) {
			return 0;
		}
		const style = window.getComputedStyle(this.tagContainerRef.value);
		const values = style.maxHeight.match(/\d+/g);
		return values ? +values[0] : 0;
	}

	private initTagContainerObserver(): void {
		const observer = new MutationObserver(this.setIsTagContainerMoreThanTwoRows);

		observer.observe(this.tagContainerRef.value as Node, {
			subtree: false,
			childList: true,
			attributeFilter: ['style', 'class']

		})
		this.observer = observer;
	}
}
