import { defineComponent, Component } from "vue";
import { CheckboxOptions } from "@/interfaces/ICheckboxOptions";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterKey } from "@/models/FilterKey";
import { FilterProviderKey } from "@/platform/FilterProviderKey";
import { InjectExternal } from "../../platform/InjectExternal";
import Checkbox from "./Checkbox.class";
import Tracking from "../tracking/Tracking.vue";

export default defineComponent({
	components: {
		Tracking: Tracking as Component
	},
	props: {
		optionName: String,
		optionValue: String,
		filterKey: String
	},
	setup: (props) => {

		const filterProvider = InjectExternal<IFilterProvider>(FilterProviderKey.FILTER_PROVIDER);
		const checkboxOptions = props as CheckboxOptions;

		const component = new Checkbox(
			checkboxOptions.optionName,
			checkboxOptions.optionValue,
			props.filterKey as FilterKey,
			filterProvider
		);

		return {
			facet: component.facet,
			selectedOptions: component.selectedOptions,
			updateSelection: component.updateSelection.bind(component),
			displayFacets: component.displayFacets,
			checkboxInputRef: component.checkboxInputRef,
			trackingData: component.trackingData
		}
	}
});
