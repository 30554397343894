import { computed, ref } from "vue";
import { Action } from "@studyportals/datalake-event-tracker";
import DisciplineFilter from "./DisciplineFilter.class";
import { ControlEvent } from "@/models/filter-tracking/ControlEvent";
import { FilterKey } from "@/models/FilterKey";
import { TrackingInput } from "@/models/filter-tracking/TrackingInput";

export class DisciplineFilterTracking {

	private readonly filter: DisciplineFilter;

	public constructor(filter: DisciplineFilter) {
		this.filter = filter;
	}

	public allDisciplinesTrackingData = computed(() => {
		if (!this.filter.allDisciplinesButtonRef?.value) {
			return;
		}

		const result = new TrackingInput(this.filter.allDisciplinesButtonRef, ref("all_disciplines"),
			ref(Action.CLICK), ControlEvent.CLICK, FilterKey.DISCIPLINES);

		return result;
	});
}
